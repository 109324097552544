import type { PartialVehicleData as VehicleData } from "shared/dist/types/vehicleData";

import { IMAGES_SERVER } from "@/config/settings";

export const CAIA_LOGO_URL = `https://api.sivince.io/media/logos/C@IA.png`;

export const iframeSlidesLogin = [
    "https://hub.after-market.events/N3K3u6x/sivince-office?embed_token=aaf274920a1ff2ea9a1ec23a334927f2",
    "https://hub.after-market.events/N3K3u6x/sivince-office?embed_token=aaf274920a1ff2ea9a1ec23a334927f2",
];
export const iframeSlides = [
    "https://hub.after-market.events/N3K3u6x/sivince-office?embed_token=aaf274920a1ff2ea9a1ec23a334927f2",
    "https://hub.after-market.events/N3K3u6x/sivince-office?embed_token=aaf274920a1ff2ea9a1ec23a334927f2",
    "https://hub.after-market.events/N3K3u6x/sivince-office?embed_token=aaf274920a1ff2ea9a1ec23a334927f2",
];
export const iframeSlidesCaridentifier = [
    {
        title: "sivinCE | Expertise",
        img: `${IMAGES_SERVER}/sivince/sivince.png`,
        makeURL: (vd: VehicleData): string =>
            `https://meetbox.ia.parts/${vd.Codif_Vin_PRF}`,
    },
];
